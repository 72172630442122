<template>
    <div v-resize="handleResize" style="position:fixed; top:0; left:0; width:100vw; height:100vh;">

        <div style="left:0; top:0px;width:100vw; height:100vh; position:absolute">
            <video id="webcam" style="display:none; width: 100vw; height: 100vh; object-fit: cover" ></video>
            <img
                id="origImage"
                crossorigin="anonymous"
                @load="initAr"
                style="display:none; position:absolute; top:0;left:0;width:1px; height:1px;"
                :src="origImageSrc"
            />
            <canvas id="canvas" style="position:absolute; left:0; top:0;width:100vw;"></canvas>
            <div v-if="item" id="overlayImageContainer" style="left:0; top:0; position:absolute; visibility: hidden">
                <img
                    id="overlayImage"
                    crossorigin="anonymous"
                    style="position:absolute; top:0;left:0;"
                    :src="overlayImageSrc"
                />
                <!--<video autoplay muted loop playsinline>
                    <source src="/videos/Borkum_Juni_1925_0.mp4" type="video/mp4">
                </video>-->
                <!--<Viewer25D
                    style="position:absolute; top:0;left:0;"
                    v-if="item.depthMapPath && show25DPhotos"
                    :imageOriginal="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'display/'+item.filePath"
                    :imageLazy="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+item.filePath"
                    :imageDepth="entrypoint+'/thumbnail/display/'+item.depthMapPath"
                    :aspectRatio="getRatioRounded(item)"
                    :horizontalThreshold="45"
                    :verticalThreshold="45"></Viewer25D>
-->

            </div>
            <div id="no_rtc" class="alert alert-error" style="display:none;"></div>
        </div>
        <v-card :style="(hideGui ? ' opacity:0;transition:opacity 500ms;' : 'opacity:1;transition:opacity 500ms;')">




            <v-tooltip left v-if="item">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="closeWin" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Close') }}</span>
            </v-tooltip>
            <v-card-actions v-if="item" style="position:fixed; bottom:20px;">
                <v-speed-dial
                    v-if="item "

                    v-model="speedDialShow"
                    direction="top"
                    transition="slide-y-reverse-transition"
                >
                    <template v-slot:activator>
                        <v-btn
                            v-model="speedDialShow"
                            icon large
                        >
                            <v-icon v-if="speedDialShow">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-dots-horizontal
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-tooltip right>

                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click.stop="toggleVoice" v-on="onTooltip" icon>
                                <v-icon v-if="audioPlaying">mdi-voice-off</v-icon>
                                <v-icon v-if="!audioPlaying">mdi-account-voice</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="audioPlaying">{{ $t('app.VoiceOff') }}</span>
                        <span v-if="!audioPlaying">{{ $t('app.VoiceOn') }}</span>
                    </v-tooltip>
                </v-speed-dial>
                <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn v-if="item['place']"
                               :to="{name: 'MapShowGeo', params: { geo: item.place.geo.latitude+','+item.place.geo.longitude }}"
                               icon large v-on="onTooltip">
                            <v-icon>icon-Marker</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Photograph.ShowOnMap') }}</span>
                </v-tooltip>
            </v-card-actions>
            <button class="additional-photographs-button" type="button"
                            v-if="overlayImageSelector.length>0"
                    >
                        <div
                            top
                            :class="selectedOverlayImage['@id'].indexOf('/retrosnaps/') > -1 ? 'retroSnapThumbnailContainer' : ''"
                            @click.stop="additionalPhotographsOpen=true">
                            <img
                                class="retroSnapThumbnail"
                                :src="getImageForSelectedOverlayImage(selectedOverlayImage,'icon').replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                                alt=""/>
                        </div>
                    </button>

            <v-btn v-if="item" @click="showCollectionInfoDialog=true" x-small class="attributionLayer" v-html="attribution"></v-btn>
        </v-card>
        <v-dialog
            v-model="showCollectionInfoDialog"
            v-if="item"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ item.collection.headline }}
                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCollectionInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle v-if="item.collection.about">{{ item.collection.about }}</v-card-subtitle>
                <v-card-text v-if="item.collection.text" v-html="item.collection.text"></v-card-text>
                <v-card-text><v-btn small @click.stop.prevent="showPhotosFromCollection">{{item.collection.photographsCount}} RetroPics</v-btn></v-card-text>

                <v-card-text v-if="this.item.collection.collectionCopyrightUrl"><a :href="this.item.collection.collectionCopyrightUrl" target="_blank">{{this.item.collection.collectionCopyrightUrl}}</a></v-card-text>
                <v-card-text v-if="this.item.collection.licenseName">
                    <a v-if="this.item.collection.licenseUrl" :href="this.item.collection.licenseUrl" rel="license" target="_blank">{{this.item.collection.licenseName}}</a>
                    <span v-else>{{this.item.collection.licenseName}}</span>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-bottom-sheet
            v-model="additionalPhotographsOpen"
            v-if="overlayImageSelector.length>0"
            max-width="400"
        >
            <v-card>
                <v-card-text>
            <v-row>
                <v-col
                    v-for="(additionalPhotograph, index) in overlayImageSelector" :key="'overlayImage_'+index"
                    class="d-flex child-flex"
                    cols="4"


                >
                    <div
                        :class="additionalPhotograph==selectedOverlayImage ? 'overlayImageSelected': ''"
                        @click.stop="selectOverlayImage(additionalPhotograph)">
                    <v-img
                        :src="getImageForSelectedOverlayImage(additionalPhotograph,'small').replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                        :lazy-src="getImageForSelectedOverlayImage(additionalPhotograph,'icon').replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                        :class="additionalPhotograph['@id'].indexOf('/retrosnaps/') > -1 ? 'retroSnapThumbnailContainer' : ''"

                        aspect-ratio="1"

                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    </div>
                </v-col>
            </v-row>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

    </div>
</template>

<style>
.retroSnapThumbnailContainer {
    /*max-width: 64px;
    max-height: 64px;*/
    display: block;
}

.retroSnapThumbnail{
    box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
}

.additional-photographs-button{
    display: block;
    right: 10px;
    position: fixed;
    bottom: 60px;
}

.overlayImageSelected{
    border: 2px solid white;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import jsQR from 'jsqr'
import jsfeat from 'jsfeat';
import { ENTRYPOINT } from '@/config/entrypoint'
import numeric from 'numeric';
import fetch from '../../utils/fetch'
import { dragscroll } from 'vue-dragscroll'
import isWebpSupported from '../../utils/isWebpSupported'
//import Viewer25D from '../viewer25d/Viewer25D'
export default {
    directives: {
        'dragscroll': dragscroll
    },
    components: {
        //Viewer25D
    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            arMode: false,
            id:null,
            showColorized:true,
            show25DPhotos: true,
            video: null,
            canvas: null,
            // central difference using image moments to find dominant orientation
            u_max: new Int32Array([15, 15, 15, 15, 14, 14, 14, 13, 13, 12, 11, 10, 9, 8, 6, 3, 0]),
            options:null,
            ctx:null,
            img_u8:null,
            img_u8_smooth:null,
            imgWarp:null,
            warpTransform: null,
            trackImage:null,
            trackImageSmooth:null,
            origImage:null,
            origImageData:null,
            origImageU8:null,
            screen_corners:null,
            num_corners:null,
            screen_descriptors:null,
            pattern_corners:[],
            pattern_descriptors:[],
            pattern_preview:null,
            matches:null,
            homo3x3:null,
            match_mask:null,
            num_train_levels: 4,
            attempts: 0,
            overlayImage:null,
            overlayImageContainer:null,
            screenWidth:0,
            screenHeight:0,
            qrCanvasElement:null,
            qRCanvasContext:null,
            additionalPhotographsOpen: false,
            dragged: false,
            dragTimeout: null,
            webpSupported: true,

            metaItem: {},
            metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
            metaUrl: null,
            metaImage: null,
            metaHashtags: [],
            showCollectionInfoDialog: false,
            overlayImageSelector: [],
            origImageSrc: null,
            overlayImageSrc: null,
            selectedOverlayImage: null,

            oldCodeId: null,
            hideGui: false,
            hideGuiTimeout: null,
            speedDialShow: false,
            audioPlaying: false,
            audioObj: null,
            compatibility: (function() {
                let lastTime = 0,
                    isLittleEndian = true,

                    URL = window.URL || window.webkitURL,

                    requestAnimationFrame = function (callback, element) {
                        const requestAnimationFrame =
                            window.requestAnimationFrame ||
                            window.webkitRequestAnimationFrame ||
                            window.mozRequestAnimationFrame ||
                            window.oRequestAnimationFrame ||
                            window.msRequestAnimationFrame ||
                            function (callback) {
                                const currTime = new Date().getTime()
                                const timeToCall = Math.max(0, 16 - (currTime - lastTime))
                                const id = window.setTimeout(function () {
                                    callback(currTime + timeToCall)
                                }, timeToCall)
                                lastTime = currTime + timeToCall
                                return id
                            }

                        return requestAnimationFrame.call(window, callback, element)
                    },

                    cancelAnimationFrame = function (id) {
                        const cancelAnimationFrame = window.cancelAnimationFrame ||
                            function (id) {
                                clearTimeout(id)
                            }
                        return cancelAnimationFrame.call(window, id)
                    },

                    getUserMedia = function (options, success, error) {
                        const getUserMedia =
                            window.navigator.getUserMedia ||
                            window.navigator.mozGetUserMedia ||
                            window.navigator.webkitGetUserMedia ||
                            window.navigator.msGetUserMedia ||
                            function (options, success, error) {
                                error()
                            }

                        return getUserMedia.call(window.navigator, options, success, error)
                    },

                    detectEndian = function () {
                        const buf = new ArrayBuffer(8)
                        const data = new Uint32Array(buf)
                        data[0] = 0xff000000
                        isLittleEndian = buf[0] !== 0xff;
                        return isLittleEndian
                    }

                return {
                    URL: URL,
                    requestAnimationFrame: requestAnimationFrame,
                    cancelAnimationFrame: cancelAnimationFrame,
                    getUserMedia: getUserMedia,
                    detectEndian: detectEndian,
                    isLittleEndian: isLittleEndian
                };
            })()

        }
    },
    metaInfo() {
        return {
            title: this.metaTitle,
            meta: [
                {
                    name: 'description',
                    content: this.metaDescription,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            settings: 'settings/items',
            error: 'photograph/show/error',
            isLoading: 'photograph/show/isLoading',
            item: 'photograph/show/retrieved',
        }),
        metaDescription() {
            return this.$t('app.MetaDescription');
        },
        searchfilters () {
            return this.$store.state.searchfilters.items || []
        },
        attribution() {
            let attribution=this.item.collection.headline;
            if(this.item.collection.licenseName) {
                attribution += ', ';
                attribution += this.item.collection.licenseUrl ? '<a href="' + this.item.collection.licenseUrl + '" rel="license noreferrer" target="_blank">' + this.item.collection.licenseName + '</a>' : this.item.collection.licenseName;
            }
            //attribution+= ' | '+'&copy; <a href="https://nimmerso.com/">nimmerso.com</a>';
            return attribution;
        }
    },
    mounted () {
        this.webpSupported = isWebpSupported();
        this.id=decodeURIComponent(this.$route.params.id);
        this.retrieve(decodeURIComponent(this.$route.params.id));


    },
    watch: {
        settings () {
            this.showColorized = this.settings.showColorized
            this.showInfosOverlay = this.settings.showInfosOverlay
            this.show25DPhotos = true; //typeof this.settings.show25DPhotos != 'undefined' ? this.settings.show25DPhotos : true
        },
        '$route.params.id': function (id) {
            this.reset()
            this.id=id;
            if (id) this.retrieve(decodeURIComponent(id))
        },
        item: function () {
            if (this.item === [] || this.item === null) return

            this.overlayImageSelector=JSON.parse(JSON.stringify(this.item['retrosnaps']));
            this.overlayImageSelector.push({
                '@id': 'colorized_version',
                filePath: this.item.filePath
            });
            this.overlayImageSelector.push({
                '@id': 'original_version',
                filePath: this.item.filePath
            });
            window.console.log(this.overlayImageSelector[0])
            this.selectedOverlayImage=this.overlayImageSelector[0];
            this.origImageSrc=this.entrypoint+'/thumbnail/display/'+this.item.filePath
            this.overlayImageSrc=this.getImageForSelectedOverlayImage(this.selectedOverlayImage,'display');
            this.metaItem = this.item
            this.metaTitle = this.getTranslation(this.metaItem).name + ' - Nimmerso'
            this.metaDescription = this.htmlAsText(this.getTranslation(this.metaItem).description +' © '+ this.attribution)
            this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.filePath
            this.metaUrl = this.getCompleteUrl()
            this.metaHashtags = []
            if (this.categories) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
            if(this.item.isBlackAndWhite===false){
                this.colorized=false;
            }
            this.arMode = true
            this.showColorized = this.settings.showColorized
            this.showInfosOverlay = this.settings.showInfosOverlay
            // lets do some fun
            this.video = document.getElementById('webcam');
            this.canvas = document.getElementById('canvas');
            this.canvas.width=window.innerWidth;
            this.canvas.height=window.innerHeight;
            this.ctx = this.canvas.getContext('2d')
            window.console.log(this.video);
            window.console.log(this.canvas);
            try {
                this.attempts = 0;
                this.video.addEventListener('loadeddata', this.readyListener);

                this.compatibility.getUserMedia({
                    video: {
                        /*width: {
                            min: 1280,
                            max: 1920,
                        },
                        height: {
                            min: 720,
                            max: 1080
                        },*/
                        //width: { ideal: 4608 },
                        //height: { ideal: 3472 },
                        facingMode: 'environment'
                    }
                }, (stream)=> {
                    if(this.video.srcObject !== undefined){
                        this.video.srcObject = stream
                    } else {
                        try {
                            this.video.src = this.compatibility.URL.createObjectURL(stream);
                        } catch (error) {
                            this.video.src = stream;
                        }
                    }
                    setTimeout(()=>{
                        this.video.play();
                    }, 500);
                },  (error)=>{
                    window.console.log(error);
                    this.canvas.style.visibility='hidden';
                    document.querySelector('#no_rtc').innerHTML='<h4>WebRTC not available.</h4>'+error;
                    document.querySelector('#no_rtc').style.visibility='shown';
                });
            } catch (error) {
                window.console.log(error);
                this.canvas.style.visibility='hidden';
                document.querySelector('#no_rtc').innerHTML='<h4>Something goes wrong...</h4>'+error;
                document.querySelector('#no_rtc').style.visibility='shown';
            }



            this.initQr()

            //let stat = new profiler();



        }

    },

    beforeDestroy () {
        window.console.log('beforeDestroy')
        this.video.pause()
        this.video.src = null
        this.arMode = false

    },
    methods: {
        ...mapActions({
            reset: 'photograph/show/reset',
            retrieve: 'photograph/show/retrieve',
        }),
        initQr () {

            this.qrCanvasElement = document.createElement('canvas')
            this.qRCanvasContext = this.qrCanvasElement.getContext('2d')

            setInterval( ()=> {
                if (this.video && this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
                    this.qrCanvasElement.height = this.video.videoHeight
                    this.qrCanvasElement.width = this.video.videoWidth
                    this.qRCanvasContext.drawImage(this.video, 0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height)
                    const imageData = this.qRCanvasContext.getImageData(0, 0, this.qrCanvasElement.width, this.qrCanvasElement.height)
                    //window.console.log(this.qrCanvasElement.width, this.qrCanvasElement.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height)
                    if (code && code.data.substr(0,18)==='https://nimmer.so/') {
                        const codeId=code.data.substr(18).split('/')[0];
                        if(codeId!=='' && codeId.length > 1 && this.oldCodeId!==codeId){
                            this.oldCodeId=codeId;


                            fetch('/short_urls/'+this.oldCodeId, {
                                method: 'GET',
                                headers: new Headers({'Content-Type': 'application/ld+json'})
                            })
                                .then(response => response.json())
                                .then((data) => {
                                    window.console.log(data.urlPath )
                                    if(data.urlPath.substr(0,10)==='/image-ar/') {
                                        if (data.urlPath !== '/image-ar/' + encodeURIComponent(this.$route.params.id)) {
                                            window.console.log(data.urlPath, '!==','/image-ar/' + encodeURIComponent(this.$route.params.id) )

                                            let parameters=data.parameters===null ? [] : data.parameters;
                                            if(data.utmSource!==null) parameters['utm_source']=data.utmSource;
                                            if(data.utmMedium!==null) parameters['utm_medium']=data.utmMedium;
                                            if(data.utmCampaign!==null) parameters['utm_campaign']=data.utmCampaign;
                                            if(data.utmTerm!==null) parameters['utm_term']=data.utmTerm;
                                            if(data.utmContent!==null) parameters['utm_content']=data.utmContent;

                                            this.$router.replace({name: 'ImageAr', params: {...parameters,id: data.urlPath.substr(10)}})
                                        }else{
                                            window.console.log(data.urlPath, '===','/image-ar/' + encodeURIComponent(this.$route.params.id) )
                                        }
                                    }else{
                                        window.location.href=code.data;
                                    }
                                })
                                .catch((e) => {
                                    window.console.log(e)
                                })








                        }
                    }
                }
            }, 1000)

        },
        demo_opt () {
            return {
                blur_size: 5,
                lap_thres: 30,
                eigen_thres: 25,
                match_threshold: 48,
            }
        },
        getImageData(imageId){
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d')
            let img = document.getElementById(imageId);
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0 );
            return context.getImageData(0, 0, img.naturalWidth, img.naturalHeight);
        },

        initAr () {
            window.console.log('initAr');
            this.options = this.demo_opt()
            this.video = document.getElementById('webcam');
            this.canvas = document.getElementById('canvas');
            //this.canvas.width=window.innerWidth;
            //this.canvas.height=window.innerHeight;
            this.ctx = this.canvas.getContext('2d')

            this.ctx.fillStyle = 'rgb(0,255,0)'
            this.ctx.strokeStyle = 'rgb(0,255,0)'

            this.img_u8 = new jsfeat.matrix_t(this.screenWidth, this.screenHeight, jsfeat.U8_t | jsfeat.C1_t)
            // after blur
            this.img_u8_smooth = new jsfeat.matrix_t(this.screenWidth, this.screenHeight, jsfeat.U8_t | jsfeat.C1_t)
            // we wll limit to 500 strongest points
            this.screen_descriptors = new jsfeat.matrix_t(32, 500, jsfeat.U8_t | jsfeat.C1_t)
            this.pattern_descriptors = []

            this.screen_corners = []
            this.pattern_corners = []
            this.matches = []
            this.screenWidth=window.innerWidth;
            this.screenHeight=window.innerHeight;
            window.console.log('screenWidth, screenHeight',this.screenWidth,this.screenHeight);
            window.console.log('videoWidth, videoHeight', this.video.videoWidth, this.video.videoHeight);
            window.console.log('canvas width & heigth', this.canvas.width,this.canvas.height);

            let i = this.screenWidth * this.screenHeight
            while (--i >= 0) {
                this.screen_corners[i] = new jsfeat.keypoint_t(0, 0, 0, 0, -1)
                this.matches[i] = this.match_t()
            }

            // transform matrix
            this.homo3x3 = new jsfeat.matrix_t(3, 3, jsfeat.F32C1_t)
            this.match_mask = new jsfeat.matrix_t(500, 1, jsfeat.U8C1_t)


            window.console.log(this.options);



            this.overlayImageContainer=document.getElementById('overlayImageContainer');


            this.train_pattern();
        },
        tick () {

            this.compatibility.requestAnimationFrame(this.tick)
            if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {



                let ratio = Math.max(this.screenWidth / this.video.videoWidth, this.screenHeight / this.video.videoHeight)
                let x = (this.screenWidth - this.video.videoWidth * ratio) / 2
                let y = (this.screenHeight - this.video.videoHeight * ratio) / 2
                //window.console.log('videoWidth, videoHeight', this.video.videoWidth, this.video.videoHeight);
                //window.console.log(x,y,ratio);
                //this.ctx = this.canvas.getContext("2d");
                this.ctx.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight,
                    x, y, this.video.videoWidth * ratio, this.video.videoHeight * ratio)




                //this.ctx.drawImage(this.video, 0, 0, this.screenWidth, this.screenHeight)
                let imageData = this.ctx.getImageData(0, 0, this.screenWidth, this.screenHeight)


                jsfeat.imgproc.grayscale(imageData.data, this.screenWidth, this.screenHeight, this.img_u8)

                jsfeat.imgproc.gaussian_blur(this.img_u8, this.img_u8_smooth, this.options.blur_size | 0)


                jsfeat.yape06.laplacian_threshold = this.options.lap_thres | 0
                jsfeat.yape06.min_eigen_value_threshold = this.options.eigen_thres | 0


                this.num_corners = this.detect_keypoints(this.img_u8_smooth, this.screen_corners, 500)

                jsfeat.orb.describe(this.img_u8_smooth, this.screen_corners, this.num_corners, this.screen_descriptors)


                // render result back to canvas
                //let data_u32 = new Uint32Array(imageData.data.buffer)
                //this.render_corners(this.screen_corners, this.num_corners, data_u32, this.screenWidth)

                // render pattern and matches
                let num_matches = 0
                let good_matches = 0
                if (this.pattern_preview) {
                    //this.render_mono_image(this.pattern_preview.data, data_u32, this.pattern_preview.cols, this.pattern_preview.rows, this.screenWidth)
                    num_matches = this.match_pattern()
                    good_matches = this.find_transform(this.matches, num_matches)
                }

                //this.ctx.putImageData(imageData, 0, 0)

                if (num_matches) {
                    //this.render_matches(this.ctx, this.matches, num_matches)
                    if (good_matches > 8){
                        this.overlayImageContainer.style.visibility='visible';
                        this.render_pattern_shape(this.ctx)
                    }else{
                        this.overlayImageContainer.style.visibility='hidden';
                    }

                }
            }
        },


        train_pattern () {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d')
            this.origImage = document.getElementById('origImage');
            this.overlayImage = document.getElementById('overlayImage');
            this.origImageData = this.getImageData('origImage');
            this.options = this.demo_opt()
            canvas.width = this.origImage.naturalWidth;
            canvas.height = this.origImage.naturalHeight;
            context.drawImage(this.origImage, 0, 0 );

            this.imgWarp = new jsfeat.matrix_t(this.screenWidth, this.screenHeight, jsfeat.U8_t | jsfeat.C3_t)
            this.warpTransform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C3_t);

            const imageData= context.getImageData(0, 0, this.origImage.naturalWidth, this.origImage.naturalHeight);
            let trackImage = new jsfeat.matrix_t(this.origImage.naturalWidth, this.origImage.naturalHeight, jsfeat.U8_t | jsfeat.C1_t)
            // after blur
            let trackImageSmooth = new jsfeat.matrix_t(this.origImage.naturalWidth, this.origImage.naturalHeight, jsfeat.U8_t | jsfeat.C1_t)

            jsfeat.imgproc.grayscale(imageData.data, this.origImage.naturalWidth, this.origImage.naturalHeight, trackImage)
            jsfeat.imgproc.gaussian_blur(trackImage, trackImageSmooth, this.options.blur_size | 0)
           // jsfeat.imgproc.gaussian_blur(trackImage, trackImageSmooth, 500, 5)
            window.console.log(this.options.blur_size, trackImageSmooth);

            let lev = 0, i = 0;
            let sc = 1.0
            let max_pattern_size = 512;
            let max_per_level = 300;
            let sc_inc = Math.sqrt(2.0); // magic number ;)
            let lev0_img = new jsfeat.matrix_t(trackImage.cols, trackImage.rows, jsfeat.U8_t | jsfeat.C1_t);
            let lev_img =  new jsfeat.matrix_t(trackImage.cols, trackImage.rows, jsfeat.U8_t | jsfeat.C1_t);
            let new_width = 0, new_height = 0;
            let lev_corners;
            let lev_descr;
            let corners_num = 0;

            let sc0 = Math.min(max_pattern_size / trackImage.cols, max_pattern_size / trackImage.rows);
            new_width = (trackImage.cols * sc0) | 0;
            new_height = (trackImage.rows * sc0) | 0;

            jsfeat.imgproc.resample(trackImage, lev0_img, new_width, new_height);
            // prepare preview
            this.pattern_preview = new jsfeat.matrix_t(new_width >> 1, new_height >> 1, jsfeat.U8_t | jsfeat.C1_t);
            jsfeat.imgproc.pyrdown(lev0_img, this.pattern_preview);
            this.pattern_corners = [];
            for (lev = 0; lev < this.num_train_levels; ++lev) {
                this.pattern_corners[lev] = [];
                lev_corners = this.pattern_corners[lev];

                // preallocate corners array
                i = (new_width * new_height) >> lev;
                while (--i >= 0) {
                    lev_corners[i] = new jsfeat.keypoint_t(0, 0, 0, 0, -1);
                }

                this.pattern_descriptors[lev] = new jsfeat.matrix_t(32, max_per_level, jsfeat.U8_t | jsfeat.C1_t);
            }

            // do the first level
            lev_corners = this.pattern_corners[0];
            lev_descr = this.pattern_descriptors[0];

            window.console.log(lev_corners,lev_descr);

            jsfeat.imgproc.gaussian_blur(lev0_img, lev_img, this.options.blur_size | 0); // this is more robust
            corners_num = this.detect_keypoints(lev_img, lev_corners, max_per_level);
            jsfeat.orb.describe(lev_img, lev_corners, corners_num, lev_descr);

            window.console.log("train " + lev_img.cols + "x" + lev_img.rows + " points: " + corners_num);

            sc /= sc_inc;

            // lets do multiple scale levels
            // we can use Canvas context draw method for faster resize
            // but its nice to demonstrate that you can do everything with jsfeat
            for (lev = 1; lev < this.num_train_levels; ++lev) {
                lev_corners = this.pattern_corners[lev];
                lev_descr = this.pattern_descriptors[lev];

                new_width = (lev0_img.cols * sc) | 0;
                new_height = (lev0_img.rows * sc) | 0;

                jsfeat.imgproc.resample(lev0_img, lev_img, new_width, new_height);
                jsfeat.imgproc.gaussian_blur(lev_img, lev_img, this.options.blur_size | 0);
                corners_num = this.detect_keypoints(lev_img, lev_corners, max_per_level);
                jsfeat.orb.describe(lev_img, lev_corners, corners_num, lev_descr);

                // fix the coordinates due to scale level
                for (i = 0; i < corners_num; ++i) {
                    lev_corners[i].x *= 1. / sc;
                    lev_corners[i].y *= 1. / sc;
                }

                window.console.log("train " + lev_img.cols + "x" + lev_img.rows + " points: " + corners_num);

                sc /= sc_inc;
            }
        },




        // UTILITIES

        detect_keypoints (img, corners, max_allowed) {
            // detect features
            let count = jsfeat.yape06.detect(img, corners, 17)

            // sort by score and reduce the count if needed
            if (count > max_allowed) {
                jsfeat.math.qsort(corners, 0, count - 1, function (a, b) {return (b.score < a.score)})
                count = max_allowed
            }

            // calculate dominant orientation for each keypoint
            for (let i = 0; i < count; ++i) {
                corners[i].angle = this.ic_angle(img, corners[i].x, corners[i].y)
            }

            return count
        },

        ic_angle (img, px, py) {
            let half_k = 15 // half patch size
            let m_01 = 0, m_10 = 0
            let src = img.data, step = img.cols
            let u = 0, v = 0, center_off = (py * step + px) | 0
            let v_sum = 0, d = 0, val_plus = 0, val_minus = 0

            // Treat the center line differently, v=0
            for (u = -half_k; u <= half_k; ++u)
                m_10 += u * src[center_off + u]

            // Go line by line in the circular patch
            for (v = 1; v <= half_k; ++v) {
                // Proceed over the two lines
                v_sum = 0
                d = this.u_max[v]
                for (u = -d; u <= d; ++u) {
                    val_plus = src[center_off + u + v * step]
                    val_minus = src[center_off + u - v * step]
                    v_sum += (val_plus - val_minus)
                    m_10 += u * (val_plus + val_minus)
                }
                m_01 += v * v_sum
            }

            return Math.atan2(m_01, m_10)
        },

        // estimate homography transform between matched points
        find_transform (matches, count) {
            // motion kernel
            let mm_kernel = new jsfeat.motion_model.homography2d()
            // ransac params
            let num_model_points = 4
            let reproj_threshold = 3
            let ransac_param = new jsfeat.ransac_params_t(num_model_points,
                reproj_threshold, 0.5, 0.99)

            let pattern_xy = []
            let screen_xy = []

            // construct correspondences
            for (let i = 0; i < count; ++i) {
                let m = matches[i]
                let s_kp = this.screen_corners[m.screen_idx]
                let p_kp = this.pattern_corners[m.pattern_lev][m.pattern_idx]
                pattern_xy[i] = {'x': p_kp.x, 'y': p_kp.y}
                screen_xy[i] = {'x': s_kp.x, 'y': s_kp.y}
            }

            // estimate motion
            let ok = false
            ok = jsfeat.motion_estimator.ransac(ransac_param, mm_kernel,
                pattern_xy, screen_xy, count, this.homo3x3, this.match_mask, 1000)

            // extract good matches and re-estimate
            let good_cnt = 0
            if (ok) {
                for (let i = 0; i < count; ++i) {
                    if (this.match_mask.data[i]) {
                        pattern_xy[good_cnt].x = pattern_xy[i].x
                        pattern_xy[good_cnt].y = pattern_xy[i].y
                        screen_xy[good_cnt].x = screen_xy[i].x
                        screen_xy[good_cnt].y = screen_xy[i].y
                        good_cnt++
                    }
                }
                // run kernel directly with inliers only
                mm_kernel.run(pattern_xy, screen_xy, this.homo3x3, good_cnt)
            } else {
                jsfeat.matmath.identity_3x3(this.homo3x3, 1.0)
            }

            return good_cnt
        },

        // non zero bits count
        popcnt32 (n) {
            n -= ((n >> 1) & 0x55555555)
            n = (n & 0x33333333) + ((n >> 2) & 0x33333333)
            return (((n + (n >> 4)) & 0xF0F0F0F) * 0x1010101) >> 24
        },

        // naive brute-force matching.
        // each on screen point is compared to all pattern points
        // to find the closest match
        match_pattern () {
            let q_cnt = this.screen_descriptors.rows
            let query_u32 = this.screen_descriptors.buffer.i32 // cast to integer buffer
            let qd_off = 0
            let qidx = 0, lev = 0, pidx = 0, k = 0
            let num_matches = 0

            for (qidx = 0; qidx < q_cnt; ++qidx) {
                let best_dist = 256
                let best_dist2 = 256
                let best_idx = -1
                let best_lev = -1

                for (lev = 0; lev < this.num_train_levels; ++lev) {
                    let lev_descr = this.pattern_descriptors[lev]
                    let ld_cnt = lev_descr.rows
                    let ld_i32 = lev_descr.buffer.i32 // cast to integer buffer
                    let ld_off = 0

                    for (pidx = 0; pidx < ld_cnt; ++pidx) {

                        let curr_d = 0
                        // our descriptor is 32 bytes so we have 8 Integers
                        for (k = 0; k < 8; ++k) {
                            curr_d += this.popcnt32(query_u32[qd_off + k] ^ ld_i32[ld_off + k])
                        }

                        if (curr_d < best_dist) {
                            best_dist2 = best_dist
                            best_dist = curr_d
                            best_lev = lev
                            best_idx = pidx
                        } else if (curr_d < best_dist2) {
                            best_dist2 = curr_d
                        }

                        ld_off += 8 // next descriptor
                    }
                }

                // filter out by some threshold
                if (best_dist < this.options.match_threshold) {
                    this.matches[num_matches].screen_idx = qidx
                    this.matches[num_matches].pattern_lev = best_lev
                    this.matches[num_matches].pattern_idx = best_idx
                    num_matches++
                }
                //

                /* filter using the ratio between 2 closest matches
                if(best_dist < 0.8*best_dist2) {
                    matches[num_matches].screen_idx = qidx;
                    matches[num_matches].pattern_lev = best_lev;
                    matches[num_matches].pattern_idx = best_idx;
                    num_matches++;
                }
                */

                qd_off += 8 // next query descriptor
            }

            return num_matches
        },

        // project/transform rectangle corners with 3x3 Matrix
        tCorners (M, w, h) {
            let pt = [{'x': 0, 'y': 0}, {'x': w, 'y': 0}, {'x': w, 'y': h}, {'x': 0, 'y': h}]
            let z = 0.0, i = 0, px = 0.0, py = 0.0

            for (; i < 4; ++i) {
                px = M[0] * pt[i].x + M[1] * pt[i].y + M[2]
                py = M[3] * pt[i].x + M[4] * pt[i].y + M[5]
                z = M[6] * pt[i].x + M[7] * pt[i].y + M[8]
                pt[i].x = px / z
                pt[i].y = py / z
            }

            return pt
        },

        render_pattern_shape (ctx) {
            // get the projected pattern corners
            let shape_pts = this.tCorners(this.homo3x3.data, this.pattern_preview.cols * 2, this.pattern_preview.rows * 2)

            ctx.strokeStyle = 'rgb(0,255,0)'
            /*ctx.beginPath()

            ctx.moveTo(shape_pts[0].x, shape_pts[0].y)
            ctx.lineTo(shape_pts[1].x, shape_pts[1].y)
            ctx.lineTo(shape_pts[2].x, shape_pts[2].y)
            ctx.lineTo(shape_pts[3].x, shape_pts[3].y)
            ctx.lineTo(shape_pts[0].x, shape_pts[0].y)

            ctx.lineWidth = 4
            ctx.stroke()*/



            //const w = this.origImage.naturalWidth;
            //const h = this.origImage.naturalHeight;
            const w = this.overlayImage.naturalWidth;
            const h = this.overlayImage.naturalHeight;


            var transform = this.computeTransform(
                [
                    [0, 0],
                    [w, 0],
                    [w, h],
                    [0, h]
                ],
                [
                    [shape_pts[0].x, shape_pts[0].y],
                    [shape_pts[1].x, shape_pts[1].y],
                    [shape_pts[2].x, shape_pts[2].y],
                    [shape_pts[3].x, shape_pts[3].y],
                ]
            );
            this.overlayImageContainer.style.transformOrigin = '0 0';
            this.overlayImageContainer.style.transform = transform;


        },

        // our point match structure
        match_t() {
            function match_t(screen_idx, pattern_lev, pattern_idx, distance) {
                if (typeof screen_idx === "undefined") { screen_idx=0; }
                if (typeof pattern_lev === "undefined") { pattern_lev=0; }
                if (typeof pattern_idx === "undefined") { pattern_idx=0; }
                if (typeof distance === "undefined") { distance=0; }

                this.screen_idx = screen_idx;
                this.pattern_lev = pattern_lev;
                this.pattern_idx = pattern_idx;
                this.distance = distance;
            }
            return match_t;
        },
        findVideoSize() {
            window.console.log('findVideoSize()');
            this.video = document.getElementById('webcam');
            this.canvas = document.getElementById('canvas');
            this.canvas.width=window.innerWidth;
            this.canvas.height=window.innerHeight;
            this.ctx = this.canvas.getContext('2d')

            if(this.video.videoWidth > 0 && this.video.videoHeight > 0) {
                this.video.removeEventListener('loadeddata', this.readyListener);
                this.onDimensionsReady(this.video.videoWidth, this.video.videoHeight);
            } else {
                if(this.attempts < 10) {
                    this.attempts++;
                    window.setTimeout(this.findVideoSize, 200);
                } else {
                    this.onDimensionsReady(this.screenWidth, this.screenHeight);
                }
            }
        },
        readyListener() {
            this.findVideoSize();
        },
        onDimensionsReady() {
            //this.initAr(width, height);
            this.compatibility.requestAnimationFrame(this.tick);
        },

        // Computes the matrix3d that maps src points to dst.
        computeTransform(src, dst) {
            let i
// src and dst should have length 4 each
            const count = 4
            const a = [] // (2*count) x 8 matrix
            const b = [] // (2*count) vector

            for (i = 0; i < 2 * count; ++i) {
                a.push([0, 0, 0, 0, 0, 0, 0, 0]);
                b.push(0);
            }

            for (i = 0; i < count; ++i) {
                let j = i + count;
                a[i][0] = a[j][3] = src[i][0];
                a[i][1] = a[j][4] = src[i][1];
                a[i][2] = a[j][5] = 1;
                a[i][3] = a[i][4] = a[i][5] =
                    a[j][0] = a[j][1] = a[j][2] = 0;
                a[i][6] = -src[i][0] * dst[i][0];
                a[i][7] = -src[i][1] * dst[i][0];
                a[j][6] = -src[i][0] * dst[i][1];
                a[j][7] = -src[i][1] * dst[i][1];
                b[i] = dst[i][0];
                b[j] = dst[i][1];
            }

            const x = numeric.solve(a, b)
            // matrix3d is homogenous coords in column major!
            // the z coordinate is unused
            const m = [
                x[0], x[3], 0, x[6],
                x[1], x[4], 0, x[7],
                0, 0, 1, 0,
                x[2], x[5], 0, 1
            ]
            let transform = 'matrix3d('
            for (i = 0; i < m.length - 1; ++i) {
                transform += m[i] + ",";
            }
            transform += m[15] + ")";
            return transform;
        },
        getRatioRounded(item){
            let ratio = 1;
            if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
            }else if(item.ratio ){
                ratio = item.ratio;
            }
            if(ratio>1.5){
                return 2;
            }else if(ratio<0.8){
                return 0.5;
            }
            return 1;
        },
        handleResize() {
            window.console.log('handleResize()');
            this.readyListener();
        },
        getTranslation (item) {
            if (typeof item['translations'] === 'undefined') return {}
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },

        toggleVoice(){

            if (!this.audioPlaying){
                //audio starten:
                if(this.audioObj===null){
                    this.audioObj=new Audio(this.entrypoint+'/getAudio/'+this.$root.$i18n.locale+'/'+this.item['@id']);
                    this.audioObj.addEventListener("canplaythrough", event => {
                        event.target.play();
                        this.audioPlaying=true;
                    });
                    this.audioObj.addEventListener("ended", () => {
                        this.audioPlaying=false;
                    });
                }else{
                    this.audioObj.play();
                    this.audioPlaying=true;
                }
            }else{
                //audio stoppen:
                this.audioObj.pause();
                this.audioPlaying=false;
            }

        },
        getImageForSelectedOverlayImage(target,size){
            if (target['@id'].indexOf('/retrosnaps/') > -1) {
                 return this.entrypoint+'/thumbnail/'+size+'/'+target.filePath;
            } else if (target['@id'].indexOf('colorized_version') > -1) {
                return this.entrypoint+'/thumbnail/colorized.'+size+'/'+target.filePath;
            } else {
                return this.entrypoint+'/thumbnail/'+size+'/'+target.filePath;
            }
        },

        selectOverlayImage (target) {
            this.selectedOverlayImage=target;
            this.additionalPhotographsOpen=false;
            this.overlayImageSrc=this.getImageForSelectedOverlayImage(target,'display');

        },
        getCompleteUrl: function () {
            return window.location.origin + this.$route.fullPath
        },
        htmlAsText (value) {

            return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
        },
        onDragStart() {
            clearTimeout(this.dragTimeout);

            this.dragged = false;
            this.dragTimeout = setTimeout(() => { this.dragged = true; }, 100); // Minimal delay to be regarded as drag instead of click
        },
        onDragClick(e) {
            if (this.dragged) {
                e.preventDefault();
                e.stopPropagation();
            }

            this.dragged = false;
        },
        closeWin () {
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')
        },


    }
}
</script>
