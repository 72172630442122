<template>
    <div class="collection-container" @scroll="handleScroll" v-if="item">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"

            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>
        <v-parallax  id="mobileCover" height="700">
            <img :src="topImageUrl" class="full-size-image"  />
        </v-parallax>
        <div class="row no-gutters">
            <div id="webCover"
                class="top-bg-image  pt-md-5 pb-md-2"
                :class="[1,2].includes(item.status) ? 'crowdfunding' : ''"
            >
                <img
                    class="mx-auto collection-cover-img web"
                    :src="topImageUrl"
                    alt="Collection Cover"
                    @dblclick.stop="coverClick(item.thumbnail)"
                    @click.stop="coverClick(item.thumbnail)"
                >
            </div>
            <div class="col-12 col-md-7 col-lg-8 mobilePostion pt-md-3">
                <div
                    v-if="item.status===1 || item.status===2"
                    class="pl-3 d-flex overflow-hidden justify-left crowdfunding-button"
                >
                    <img
                        alt=""
                        @click="crowdfundingDrawer=true"
                        class="crowdfunding-img d-block d-md-none"
                        src="@/assets/img/Crowdfunding_Lady_512x512.png"
                    />

               </div>

                <v-tooltip leftd>
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="closeWin" icon
                               class="closeWinButton close-button"
                               right
                               fixed
                               v-on="onTooltip">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Close') }}</span>
                </v-tooltip>
                <div v-if="item" class="text-justify card-web">
                    <div class="album row no-gutters mt-0 mt-md-3">
                        <div
                            v-if="[1,2].includes(item.status) && item.crowdfundingRequired"
                            class="col-12 col-md-auto  crowdfunding-progress-bar-wrapper px-3"
                            :class="scrollY"
                        >
                            <div v-if="item.crowdfundingRequired && item.crowdfundingRequired*0.5 >= item.crowdfundingFunded"
                                class="progress-bar-text-left d-block d-md-none"
                                @click="crowdfundingDrawer=true"
                            >
                                <span>{{ formatEuros(item.crowdfundingFunded) }}</span>
                                <span class="cents">{{ formatCents(item.crowdfundingFunded) }} €</span>
                            </div>
                            <div v-if="item.crowdfundingRequired && item.crowdfundingRequired*0.5 >= item.crowdfundingFunded"
                                class="progress-bar-text d-block d-md-none"
                                @click="crowdfundingDrawer=true"
                            >
                               von  {{ item.crowdfundingRequired }}€
                            </div>
                            <div v-else-if="item.crowdfundingRequired"
                                class="progress-bar-text d-block d-md-none"
                                @click="crowdfundingDrawer=true"
                                >
                                noch  {{item.crowdfundingRequired - item.crowdfundingFunded }}€
                            </div>

                            <v-progress-linear
                                @click="crowdfundingDrawer=true"
                                class="main-progress-bar crowdfunding-progress-bar"
                                :value="crowdfundingValue"
                                color="#79bdfa"
                            >
                            </v-progress-linear>
                            <img
                                @click="crowdfundingDrawer=true"
                                class="progress-hat"
                                src="@/assets/img/Hat_fancy_sm.svg"
                            />
                        </div>
                        <div v-if="item.crowdfundingRequired && item.crowdfundingRequired*0.5 >= item.crowdfundingFunded"
                            class="progress-bar-text-wider-req"
                            @click="crowdfundingDrawer=true"
                        >
                            <p class="align-content-center"
                            >von {{ item.crowdfundingRequired }}€
                            </p>
                        </div>
                        <div v-else-if="item.crowdfundingRequired"
                            class="progress-bar-text-wider-req"
                            @click="crowdfundingDrawer=true"
                            >
                            <p class="align-content-center"
                            >noch {{ item.crowdfundingRequired - item.crowdfundingFunded}}€
                            </p>
                        </div>
                        <div v-if="item.crowdfundingRequired && item.crowdfundingRequired*0.5 >= item.crowdfundingFunded"
                            class="progress-bar-text-wider"
                            @click="crowdfundingDrawer=true"
                        >
                            <p class="align-content-center">
                                <span>{{ formatEuros(item.crowdfundingFunded) }}</span>
                                <span class="cents">{{ formatCents(item.crowdfundingFunded) }} €</span>
                            </p>
                        </div>

                        <div class="album-description col-md" >
                            <div class="row no-gutters  " >
                                <div class="col-md-8 col-lg-8 px-3 collection-title">
                                    <h3 class="text-center " style="">{{ item && item['headline'] }}</h3>
                                </div>
                                <div class="col-md-8 col-lg-8  collection-description mb-0 mb-md-0">
<!--                                    <p class="font-weight-bold mb-3 px-3 px-md-0" v-if="item['about']" v-html="item['about']"></p>-->
                                    <div
                                        id="webDescription"
                                        class="px-3 album-text album-description-text custom-scrollbar" style="height: 160px; text-align: left"
                                        v-if="item['about'] && (showAll || item.crowdfundingRequired)"
                                        v-html="item['about']"
                                    >
                                    </div>
                                    <div
                                        id="mobileDescription"
                                        class="px-3 album-text"
                                        :class="{ 'album-description-text': isFededOut }"
                                        style="text-align: left"
                                        :style="{ height: height }"
                                        v-if="item['about']&& (showAll || item.crowdfundingRequired)"
                                        v-html="item['about']"

                                    >
                                    </div>
                                    <div class="expand_button mt-3 mb-3">
                                        <v-icon  @click="toggle" class="mr-2" :class="{'rotate': isExpanded}">
                                            mdi-chevron-down
                                        </v-icon>
                                    </div>
                                    <div  v-if="item.status===1 || item.status===2" class="crowdfunding-lady-wider-screens">
                                        <img
                                            width="250px"
                                            @click="crowdfundingDrawer=true"
                                            class=""
                                            src="@/assets/img/Crowdfunding_Lady_512x512.png"
                                        />
                                    </div>
                                    <div  v-if="item.status===1 || item.status===2" class="hat-fancy-wider-screens">
                                        <img
                                            class="progress-hat-wider-screens"
                                            @click="crowdfundingDrawer=true"
                                            src="@/assets/img/Hat_fancy_sm.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="album-caption"><p v-if="item['place'] && item['place']['name']">
                        {{ $t('app.Photograph.Place') }}: {{ item['place']['name'] }}</p>
                    </div>
                    <div v-if="!(item.status==1 || item.status==2)">
                        <div v-if="this.item.collectionCopyrightUrl">
                            <a
                                :href="this.item.collectionCopyrightUrl"
                                target="_blank">
                                {{ this.item.collectionCopyrightUrl }}
                            </a>
                        </div>
                        <div v-if="this.item.licenseName">
                            <a v-if="this.item.licenseUrl" :href="this.item.licenseUrl" rel="license"
                               target="_blank">{{ this.item.licenseName }}</a>
                            <span v-else>{{ this.item.licenseName }}</span>
                        </div>
                        <div>Diese Sammlung enthält {{ this.totalItems }} RetroPics</div>
                    </div>
                </div>
                <div v-if="offsetTop>stickyTitleShowPos" class="stickytitle">
                    <h1 class="mb-2">
                        {{item && item['headline']}}
                    </h1>
                </div>
                <v-footer class="photographCollectionFooter font-italic font-weight-thin" v-if="item">
                    <ShareButton v-if="metaUrl"
                                 :url="metaUrl"
                                 :title="metaTitle"
                                 :description="metaDescription"
                                 quote="Schau dir diese Sammlung auf Nimmerso an"
                                 :hashtags="'nimmerso,'+metaHashtags.join(',')"
                                 :media="metaImage"
                                 twitterUser="nimmerso"
                    ></ShareButton>
                    <v-tooltip top
                               v-if="personItem && item && item.owner && personItem['@id']===item.owner['@id'] && item['@type']==='https://schema.org/photographCollection'">
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn :to="{name: 'photographCollectionUpdate', params: { id: item['@id'] }}" v-on="onTooltip"
                                   icon>
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Edit') }}</span>
                    </v-tooltip>
                    <v-tooltip top
                               v-if="personItem && item.owner && personItem['@id']===item.owner['@id'] && item && item['@type']==='https://schema.org/photographCollection'">
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn @click="deleteItem(item)" v-on="onTooltip" icon color="error">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Delete') }}</span>
                    </v-tooltip>
                </v-footer>
            </div>
        </div>
        <div class="row no-gutters wide-screen-items">
            <div class="col-12">
                <div
                    class="text-justify pa-1"
                    style="padding-bottom: 100px; background-color: #000000;"
                    v-if="item"
                >
                    <div
                        v-if="(allItems.filter((item)=>!item.isHidden).length>0) && !(item.status==0 || item.status==3)"
                        class="masonry-wrapper" style="margin-bottom:60px;">
                        <div class="masonry" style="min-height:800px">
                            <div
                                :class="'masonry-item'+getRatioClass(slide)"
                                v-for="(slide) in allItems.filter((item)=>!item.isHidden)"
                                :key="slide['@id']"

                            >
                                <v-img v-if="(!slide.depthMapPath || !show25DPhotos)"
                                       v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"

                                       class="masonry-content"
                                       :aspect-ratio="getRatioRounded(slide)"
                                       :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))+
                               (slide.thumbnailCoordinates && slide.thumbnailCoordinates.feed ? '?c='+slide.thumbnailCoordinates.feed.left+','+slide.thumbnailCoordinates.feed.top+','+slide.thumbnailCoordinates.feed.width+','+slide.thumbnailCoordinates.feed.height+',' : '')"
                                       :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))+
                               (slide.thumbnailCoordinates && slide.thumbnailCoordinates.feed ? '?c='+slide.thumbnailCoordinates.feed.left+','+slide.thumbnailCoordinates.feed.top+','+slide.thumbnailCoordinates.feed.width+','+slide.thumbnailCoordinates.feed.height+',' : '')"

                                       @dblclick.stop="carouselItemClick(slide['@id'])"
                                       @click.stop="carouselItemClick(slide['@id'])"
                                >
                                    <v-img v-if="slide.retrosnaps.length>0"
                                           :class="slide.retrosnaps.length>0 ? 'retrosnapImg fadingEffect' : ''"
                                           :src="slide.retrosnaps.length > 0 ? entrypoint+'/thumbnail/list/'+slide.retrosnaps[slide.retrosnaps.length-1].filePath.replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg')) : entrypoint+'/thumbnail/list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                                           :lazy-src="slide.retrosnaps.length>0 ? entrypoint+'/thumbnail/lazy/'+slide.retrosnaps[slide.retrosnaps.length-1].filePath : entrypoint+'/thumbnail/lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                                    ></v-img>

                                </v-img>

                                <Viewer25D
                                    v-if="slide.retrosnaps.length===0 && slide.depthMapPath && show25DPhotos"
                                    :imageOriginal="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath"
                                    :imageLazy="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath"
                                    :imageDepth="entrypoint+'/thumbnail/list/'+slide.depthMapPath"
                                    :aspectRatio="getRatioRounded(slide)"
                                    :horizontalThreshold="45"
                                    :verticalThreshold="45"></Viewer25D>
                                <div class="retrosnapOverlay"
                                     @dblclick.stop="carouselItemClick(slide['@id'])"
                                     @click.stop="carouselItemClick(slide['@id'])"
                                     v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"
                                >
                                    <v-card-actions
                                        transition="fade-transition"
                                        class="homevcardactions">
                                        <v-spacer></v-spacer>

                                        <v-tooltip top
                                                   v-if="personItem && item && item.owner && personItem['@id']===item.owner['@id'] && item['@type']==='https://schema.org/photographCollection'">
                                            <template v-slot:activator="{ on: onTooltip }">
                                                <v-btn
                                                    @click.native.stop.prevent="removePhotographFromGallery(slide)"
                                                    v-on="onTooltip"
                                                    icon>
                                                    <v-icon>mdi-image-remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                                        </v-tooltip>
                                        <v-btn v-if="typeof slide.retrosnaps !=='object' && slide.retrosnaps.length>1"
                                               @click.native.stop.prevent=""
                                               :to="{name: 'PhotographRetrosnaps'+ (showColorized ? 'Color' : ''), params: {id: slide['id']}}"
                                               icon>
                                            <v-icon>icon-RetroSnap</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                                <div v-if="slide.backFilePath" class="flipsideIcon">
                                    <img style="width:32px;height:32px" src="/img/icons/FlipSide_Available_complete.svg"
                                         alt="flip"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <collage-images v-if="item.status===1 && item.previewThumbnails "
            :itemId=item.id
            :item=item
        />
        <v-navigation-drawer
            v-model="crowdfundingDrawer"
            right
            fixed
            floating
            hide-overlay
            :width="$vuetify.breakpoint.smAndUp ? '320px' : '100%'"
            style="z-index:40000;"
            class="fill-height"

        >
            <v-btn class="close-button" top right absolute icon @click="crowdfundingDrawer = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-divider></v-divider>
            <v-list dense style="overflow-y:auto; flex-grow: 5">
                <v-list-item v-for="(perk, index) in perksWithProducts" :key="index">
                    <v-expand-transition>
                        <perk :value="perk" :handle-perk-config="onPerkSelect"></perk>
                    </v-expand-transition>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-dialog
            v-if="showLoginDialog"
            v-model="showLoginDialog"
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="text-center">
                <v-toolbar
                >{{ $t('app.Comment.PleaseLogIn') }}
                </v-toolbar>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showLoginDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('app.PhotographCollection.LoginText') }}<br/>
                    {{ $t('app.PhotographCollection.LoginText2') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="goToStart">{{ $t('app.Comment.LoginRegister') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
@import "../../styles/custom-scrollbar.css";

#mobileCover{
    display: none;
}
#mobileDescription{
    display: none;
}
.v-main {
    margin-bottom: unset !important;
    margin-top: unset !important;
}
.cents {
    font-size: 0.75em; /* Adjust the size as needed */
}
.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
.collection-container {
    background: linear-gradient(to right, #aea48d 0%, #313b45 60%);
    width: 100vw;
    overflow-y: auto;
}
.card-web{
    margin-left: 30px;
}
.top-bg-image.crowdfunding {
    display: flex;
    align-items: baseline;
}
.custom-parallax .v-parallax__image-container{
    background-size: 50%;/* Adjust to your needs */
    background-position: center !important;
}
.full-size-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image retains its full size */
    object-position: center; /* Center the image */
}
.v-parallax__content{
    padding: 0 !important;
}
.wide-screen-items{
    margin-top: 50px !important;
}
.collection-cover-img.web{
    width: 100%;
    height: 300px;
    object-fit: contain;
    object-position: bottom;
}
.collection-cover-img {
    position: relative;
    left: 16px;
    width: 100%;
    min-height: 515px;
    box-shadow: -1px 5px 10px 4px #232323;
    border-radius: 3px;
}
.for-shadow{
    height: 16px;
    position: relative;
    top: -22px;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), #1F1F1F)
}
.collection-title {
    border-top-right-radius: 4px;
    background: #282c39;
    color: #ffe89a !important;
    font-weight: 100;
}
.progress-bar-text-wider{
    display: flex;
    color: #383838;
    transform: rotate(-90deg);
    justify-content: center;
    position: absolute;
    font-size: 38px;
    top: 415px;
    margin-left: 2px;
    width: 107px;
}
.progress-bar-text-wider-req{
    display: flex;
    transform: rotate(-90deg);
    font-size: 25px;
    color: #383838;
    position: absolute;
    top: 230px;
    left: 450px;
}
.collection-title>h1{
    font-size: 28px;
}
.album-text {
    hyphens: auto;
}
.photographCollectionFooter {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.7;
    z-index: 5;
}
.albumThumbnail {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}
.collection-description {
    position: relative;
    text-align: center;
    background: #282c39;
    border-bottom-right-radius: 4px;
    min-height: 480px;
}

.mobile-progress-bar{
    display: none;
}
.albumThumbnail img {
    /*transition: width 50ms, height 50ms*/
}
.album {
    transition: width 500ms, height 500ms
}
.stickytitle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 8px 0 8px;
    background: linear-gradient(180deg, #282c39 0%, #000000 50%, #575a51 100%);
}
.album-description-text {
    color: #ece5de;
}

.crowdfunding-lady-wider-screens {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 40%;
    top: 230px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.album-description-text ::-webkit-scrollbar {
    display: none;
}

.bg-placeholder {
    background-color: #00000000;
}
.crowdfunding-button {
    position: relative;
    cursor: pointer;
}
.crowdfunding-img {
    top: 0;
    position: sticky;
    width:250px;
    height: 0;
    max-height: 250px;
    cursor:pointer;
    object-fit:cover;
    object-position: top;
    transition: 1s height;
    z-index: 10;
}
.crowdfunding-progress-bar-wrapper {
    background: #282c39;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    border-radius: 4px 0 0 4px;
}
.crowdfunding-progress-bar {
    height: 65px !important;
    transform: rotate(270deg);
    border: #79bdfa 2px solid;
    border-radius: 8px;
    min-width: 450px;
}
.progress-hat {
    display: none;
    position: absolute;
    cursor:pointer;
    filter: drop-shadow(0px 6px 4px #313b45);
    width: 100px;
    top: -75px;
    right:8%;
}
.progress-hat-wider-screens{
    position: absolute;
    filter: drop-shadow(4px 6px 4px #0a0a0a );
    width: 80px;
    top: 415px;
    right: 100px;
}
.progress-bar-text-wider-screens{
    text-shadow: 1px 1px 4px black;
    position: absolute;
    filter: drop-shadow(0px 6px 4px #313b45);
    width: 130px;
    top: 220px;
    right: 320px;
}
.v-progress-linear__content {
    box-shadow: inset -8px 1px 7px 1px #282c39;
}

.v-progress-linear__buffer{
    box-shadow:inset -5px -1px 9px 2px #282c39;
    background-image: /* On "bottom" */ repeating-linear-gradient(294deg, #d4d0d0, transparent 0px, #e0e0e0 2px, #fcf0f0 10px), /* on "bottom" */ linear-gradient(to bottom, #eee, #bbbaba 2px);
}
.v-progress-linear__determinate{
    box-shadow: inset 0px 3px 7px -3px #282c39;
}
.mobilePostion{
    margin-top: 0;
}
.close-button {
    z-index: 40002;
    backdrop-filter: blur(10 px);
    background-color: rgba(255, 255, 255, 0.3) !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
.v-list.v-sheet.theme--dark.v-list--dense {
    padding: 0 0;
}
.v-list.v-sheet.theme--dark.v-list--dense>div {
    padding: 0;
}
.hat-fancy-wider-screens{
    cursor: pointer;
}
#webCover{
    cursor: pointer;
}
.expand_button{
    display: none;
}
@media screen and (max-width: 959px) {
    .expand_button{
        display: block;
    }
    .collection-container{
        background: none;
    }
    .v-progress-linear__determinate{
        box-shadow: inset 0px 7px 7px -3px #282c39;
    }
    .v-progress-linear__buffer {
        box-shadow: inset 2px 4px 7px 1px #282c39
    }
    .progress-bar-text-wider{
        display: none!important;
    }
    .progress-bar-text-wider-req{
        display: none!important;
    }
    .wide-screen-items{
        margin-top: 0 !important;
    }
    .card-web{
        margin-left: 0;
    }
    .album-description-text{
        mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    }
    .mobile-progress-bar{
        display: block;
    }
    #mobileCover{
        padding: 0;
        display: block;
    }
    #mobileDescription{display: block}
    #webDescription{display: none}
    #webCover{display: none;}
    .collection-cover-img{
        outline: unset;
        box-shadow: unset;
        position: unset;
        left: unset;
    }
    .mobilePostion{
        position: relative;
        margin-top:-75px;
        transition: margin-top 1s
    }
    .crowdfunding-image-frame {
        max-width: min(100vw, 80vh, 1024px);
        max-height: 78vh;
        width: 100%;
        height: calc(100vh - 100px);
    }
    .crowdfunding-lady-wider-screens {
        display: none;
    }
    .hat-fancy-wider-screens{
        display: none;
    }
    .collection-title {
        box-shadow: 1px -28px 36px 30px #282c39;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        font-size: 22px;
        text-align: center !important;
    }
    .collection-description {
        border-bottom-right-radius: 0;
        min-height: unset;
    }
    .crowdfunding-progress-bar-wrapper {
        background: unset;
        width: unset;
        font-size: 22px;
        border-radius: 0 0 8px 8px;
}
    .crowdfunding-progress-bar {
        min-width: 100%;
        position: relative;
        max-width: unset;
        height: 60px !important;
        transform: rotate(0deg);
    }
    .progress-hat {
        display: block;
    }
    .progress-bar-text {
        position: absolute;
        color: #383838;
        transform: rotate(0);
        font-size: 18px;
        width: 100%;
        text-align: center;
        margin: auto;
        font-weight: 800;
        z-index: 1;
    }
    .progress-bar-text-left {
        position: absolute;
        color: #383838;
        transform: rotate(0);
        font-size: 21px;
        width: 100%;
        left: -38%;
        text-align: center;
        margin: auto;
        font-weight: 800;
        z-index: 1;
    }
    .crowdfunding-progress-bar-wrapper>.progress-bar-text {
        font-size: 16px;
        bottom: 8px;
    }
    .album-description-text p {
        background: linear-gradient(180deg, #282d3a, transparent);
    }
    .album-description-text {
        height: 150px;
    }
    .v-progress-linear__content {
        box-shadow: inset 3px 7px 11px 1px #282d3a;
    }
}

@media screen  and ( max-width:1259px) and (min-width: 960px) {

    .crowdfunding-lady-wider-screens{
        top: 230px;
        right: 25%;
    }
    .progress-hat-wider-screens{
        top: 415px;
        right: 10px;
    }
    .progress-bar-text-wider-screens{
        right: 20px;
    }

}
</style>
<script>
import {ENTRYPOINT, IMAGE_ENTRYPOINT} from '@/config/entrypoint'
import isWebpSupported from '../../utils/isWebpSupported'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Viewer25D from '../viewer25d/Viewer25D'
import ShareButton from '../share/ShareButton'
import { Scroll } from 'vuetify/lib/directives'
import Rellax from 'rellax'
import Perk from './Perk'
import CollageImages from './CollageImages.vue';


export default {
    components: {
        //CommentItem,
        //CommentDialog,
        CollageImages,
        ShareButton,
        Viewer25D,
        Perk
    },
    directives: {
        Scroll
    },
    computed: {
        ...mapState(['order/order_collection']),
        ...mapGetters({
            deleteError: 'photographcollection/del/error',
            //error: 'photographcollection/show/error',
            isLoading: 'photographcollection/show/isLoading',
            item: 'photographcollection/show/retrieved',
            items: 'photograph/list/items',
            view: 'photograph/list/view',
            personLastOrder: 'order/show/personOrder',
            isPhotographListLoading: 'photograph/list/isLoading',
            settings: 'settings/items',
            personItem: 'person/show/retrieved',
            token: 'user/token/token',
            refresh_token: 'user/token/refresh_token',
            token_data: 'user/token/token_data',
            createdOrder: 'order/create/created',
            /*commentDeletedItem: 'comment/del/deleted',
            commentError: 'comment/list_photographcollection/error',
            commentItems: 'comment/list_photographcollection/items',
            commentIsLoading: 'comment/list_photographcollection/isLoading',
            commentView: 'comment/list_photographcollection/view',
            commentCreated: 'comment/create/created',
            commentRetrieved: 'comment/update/retrieved',
            commentUpdated: 'comment/update/updated',
            commentCreateViolentations: 'comment/create/violentations',*/
            totalItems: 'photograph/list/totalItems',
            products: 'product/list/items',
            order_collection: 'order/order_collection/items'
        }),
        metaDescription() {
            return this.$t('app.MetaDescription');
        },
        topImageUrl(){
            return `${this.entrypoint}/photograph_collections/${this.item.thumbnail}/display`
        }
    },
    watch: {
        settings () {
            this.showColorized = this.settings.showColorized
            this.showGalleryInfosOverlay = this.settings.showInfosOverlay
            this.show25DPhotos = typeof this.settings.show25DPhotos != 'undefined' ? this.settings.show25DPhotos : true
        },
        '$route.params.id': function (id) {
            window.location.reload()
            if (this.oldId === id) return
            if (this.$route.name !== 'photographCollectionShow') return
            //window.console.log('watch $route.params.id ', this.$route.name);

            this.oldId = id
            //this.reset()
            if (id) this.retrieve(decodeURIComponent(id))
            //this.checkActiveComment()
            this.onScroll()
        },
        /*'$route.params.comment_id': function () {
            this.checkActiveComment()
            if(this.$route.name!=='photographCollectionShow') return;

        },*/
        personItem: function () {
            this.photographcollections = this.personItem.photographCollections
        },
        item: function () {
            if (this.item === [] || this.item === null) return
            //this.isActive=true;
            this.metaItem = this.item
            this.metaTitle = this.metaItem.headline + ' - Nimmerso'
            this.metaDescription = this.htmlAsText(this.metaItem.description)
            this.metaImage = this.entrypoint + '/photograph_collections/' + this.metaItem.thumbnail + '/list.jpeg'
            this.metaUrl = this.getCompleteUrl()
            this.metaHashtags = []
            this.crowdfundingValue = (this.item.crowdfundingFunded / this.item.crowdfundingRequired) * 100
            //this.getComments('/comments?photographCollection=' + this.$route.params.id)
            if (this.offsetTop) {
                //window.console.log('offsetTop', this.offsetTop);
                window.scrollTo(0, this.offsetTop)
            }
            this.onScroll()
            //this.getComments('/comments?galleries=' + this.$route.params.id).then(result=>{window.console.log(result)})
            this.loadPerkProducts()

        },
        items: function () {
            this.allItems.push(...this.items)
            if (window.IntersectionObserver) {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('fadingEffect')
                        } else {
                            entry.target.classList.remove('fadingEffect')
                        }
                    })
                }, {rootMargin: '0px 0px 0px 0px'})
                document.querySelectorAll('.retrosnapImg').forEach(img => {
                    observer.observe(img)
                })
            }
            setTimeout(() => {
                if (this.rellax == null || (typeof this.rellax == 'object' && typeof this.rellax.refresh == 'undefined')) {
                    //console.log('new rellax');
                } else {
                    //console.log(typeof this.rellax);
                    this.rellax.destroy()
                }
                this.rellax = new Rellax('.rellax', {
                    //center: true,
                    round: true
                })
                window.rellax = this.rellax
            }, 1000)
        },
        products: function () {

            this.perksWithProducts = []
            if (this.item.perks) this.item.perks.forEach(perk => {
                let perkWithProduct = perk
                perkWithProduct.product = this.products.find(product => product['@id'] === perk.product)
                this.perksWithProducts.push(perkWithProduct)

            })
            window.console.log('perksWithProducts', this.perksWithProducts)

        },
        /*commentItems: function () {
            this.checkActiveComment()
        },*/

        offsetTop: function () {

        },
        showLoginDialog: function(){
            this.crowdfundingDrawer=false;
        },
        createdOrder: function (createdOrder) {
            if (!createdOrder) {
                return
            }
            this.$router.push({name: 'OrderCollectionCreate', params: {id: this.item.id}})
        },
    },
    data: () => ({
        entrypoint: ENTRYPOINT,
        imageEntrypoint: IMAGE_ENTRYPOINT,
        showColorized: false,
        showGalleryInfosOverlay: false,
        showInfo: false,
        show25DPhotos: true,
        webpSupported: true,
        photographcollections: [],
        /*activeComment: null,
        showCommentDialog: false,
        newComment: {box: null, isPrivate: false},*/
        oldScrollPos: 0,
        metaItem: {},
        metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
        metaUrl: null,
        metaImage: null,
        metaHashtags: [],
        attributes: [],
        activeTouchEle: null,
        albumThumbnailHeight: window.innerHeight,
        albumThumbnailTop: 0,
        albumThumbnailOpacity: 1,
        offsetTop: 0,
        isActive: false,
        oldId: null,
        showAll: false,
        stickyTitleShowPos: Math.min(window.innerHeight, window.innerWidth),
        //showCommentLoginDialog: false,
        error: null,
        allItems: [],
        crowdfundingValue: 0,
        crowdfundingDrawer: false,
        perksWithProducts: [],
        selectedPerk: null,
        selectedPerkConfig: null,
        showLoginDialog:false,
        scrollY: 0,
        isExpanded: false,
        height: '160px',
        isFededOut:true

    }),

    beforeDestroy () {
        //window.console.log('beforeDestroy',this.isActive);
        this.isActive = false
        this.reset()
    },
    deactivated () {
        //window.console.log('deactivated',this.isActive);
        this.isActive = false
        //this.reset()
        this.showGalleryInfosOverlay = false
    },
    activated () {
        //window.console.log('activated',this.isActive);
        this.isActive = true
        if (this.offsetTop) {
            //window.console.log('offsetTop', this.offsetTop);

            setTimeout(() => {
                window.scrollTo(0, this.offsetTop)
            }, 500)
        }
        setTimeout(() => {
            this.onScroll();
        }, 500)



    },
    metaInfo() {
        return {
            title: this.metaTitle,
            meta: [
                {
                    name: 'description',
                    content: this.metaDescription,
                },
            ],
        };
    },

    created () {
        //window.console.log('created',this.isActive);
        window.addEventListener('scroll', this.handleScroll);
        this.isActive = true
        this.webpSupported = isWebpSupported()
        this.retrieve(decodeURIComponent(this.$route.params.id))
        //this.showColorized = this.settings.showColorized
        this.showColorized = this.$route.path.indexOf('/color') !== -1
        this.showGalleryInfosOverlay = this.settings.showInfosOverlay
        this.show25DPhotos = this.settings.show25DPhotos
        if (this.token_data) {
            this.personRetrieve('/people/' + this.token_data.person_id)
        } else {
            window.console.log('no tokenData!')
        }
        this.getPage('photographs?collection=' + this.$route.params.id + '&parentPhotograph[exists]=false')
        this.onScroll()
    },
    mounted () {
        //window.console.log('mounted',this.isActive);
        this.restoreScrollPosition();
        this.isActive = true
        this.onScroll()
    },
    destroyed() {
        window.removeEventListener('scroll')
    },
    methods: {
        ...mapMutations({
            setordercollection: 'order/order_collection/set'
        }),
        ...mapActions({
            del: 'photographcollection/del/del',
            reset: 'photographcollection/show/reset',
            retrieve: 'photographcollection/show/retrieve',
            getPage: 'photograph/list/default',
            updatephotographCollection: 'photographcollection/update/update',
            updateRetrievedphotographCollection: 'photographcollection/update/updateRetrieved',
            retrievephotographCollection: 'photographcollection/update/retrieve',
            personRetrieve: 'person/show/retrieve',
            personOrder: 'order/show/personOrder',
            gettokendata: 'user/token/gettokendata',
            getProducts: 'product/list/default',
            createOrder: 'order/create/create',
            //createComment: 'comment/create/create',
            //getComments: 'comment/list_photographcollection/default',
        }),
        getTranslation (item) {
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },
        formatEuros(amount) {
            return Math.floor(amount); // Get the integer part
        },
        formatCents(amount) {
            const cents = Math.round((amount - Math.floor(amount)) * 100);
            return cents > 0 ? `.${cents}` : '';
        },
        handleScroll() {
            this.scrollY = window.scrollY;
            if (this.view && this.view['hydra:next'] && !this.isPhotographListLoading) this.getPage(this.view['hydra:next'])
            // This section is meant for crowdfunding collections Only
            if (this.item.status!==1){
                return
            }
            if (window.screen.availWidth <= 959) {
                this.$el.querySelector('.mobilePostion').style.marginTop = -400 + 'px'
                this.$el.querySelector('.crowdfunding-img').style.height = 250 + 'px'
            } else {
                this.$el.querySelector('.mobilePostion').style.marginTop = 'unset'
            }

        },
        // Save the scroll position in localStorage
        saveScrollPosition() {
            localStorage.setItem('scrollPosition', window.scrollY);
            localStorage.setItem(`cacheKey`, JSON.stringify(this.view));
        },
        // Restore the scroll position from localStorage
        restoreScrollPosition() {
            const scrollPosition = localStorage.getItem('scrollPosition');

            const cachedData = localStorage.getItem('cacheKey');
            this.view = JSON.parse(cachedData);
            if (scrollPosition !== null) {
                if (this.view && this.view['hydra:next'] ){
                    this.getPage(this.view['hydra:next']).then(() => {
                        setTimeout(() => {
                            window.scrollTo(0, parseInt(scrollPosition, 10));
                        }, 1000);
                        localStorage.removeItem('scrollPosition');
                        localStorage.removeItem(`cacheKey`);
                    });
                }else{
                    this.getPage(this.view['hydra:last']).then(() => {
                        setTimeout(() => {
                            window.scrollTo(0, parseInt(scrollPosition, 10));
                        }, 1000);
                        localStorage.removeItem('scrollPosition');
                        localStorage.removeItem(`cacheKey`);
                    });
                }


            }
         },
        carouselItemClick (item) {
            this.saveScrollPosition()
             this.$router.push({name: 'PhotographShow' + (this.showColorized ? 'Color' : ''), params: {id: item}})
        },

        coverClick (item) {
            this.$router.push({name: 'CoverShow', params: {id: item}})
        },
        deleteItem (item) {
            if (window.confirm('Are you sure you want to delete this item?')) {
                this.del(item).then(() => this.$router.push({name: 'photographCollectionList'}))
            }
        },
        toggle() {
            this.isExpanded = !this.isExpanded;
            this.height = !this.isExpanded ? '160px' : 'auto';
            this.isFededOut = !this.isFededOut;
        },
        arrayToIris (datas) {
            let iriArray = []
            datas.forEach((data) => {
                iriArray.push(data['@id'])
            })
            return iriArray
        },


        /*onSendCommentForm (commentData) {
            this.newComment = commentData
            if (this.newComment.id == null) {
                this.createComment(this.newComment)
            } else {
                this.updateComment(this.newComment)
            }

        },*/
        /*updateCommentField (field, value) {
            //window.console.log(field, value)
            Object.assign(this.newComment, {[field]: value})
        },
         */
        /*addComment () {
            if(this.token == null){
                //not logged in:
                this.showCommentLoginDialog = true;
                return;
            }
            this.showCommentDialog = true
            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'photographCollection': this.item['@id'],
                isBackside: false
            }
        },*/
        /*commentDialogClose () {
            this.pathEditMode = false
            this.box = null
        },

         */
        /*doCommentDialogClose () {
            this.showCommentDialog = false
            this.commentDialogClose()
        },
        editComment (commentItem) {
            this.showInfo = false
            this.newComment = commentItem
            this.showCommentDialog = true
        },
        deleteComment (commentItem) {
            if (window.confirm(this.$t('app.Comment.DeleteConfirm'))) {
                return fetch(commentItem['@id'], {
                    method: 'DELETE',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(commentItem)
                })
                    .then(() => {
                        this.getComments('/comments?photographCollection=' + this.$route.params.id)
                        //window.console.log('deleted')
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            }
        },
        reportInappropriateComment (commentItem) {
            window.console.log('reportInappropriateComment', commentItem)
        },
        submitComment () {
            this.showCommentDialog = false
            this.commentDialogClose()
            if (this.newComment['@id']) {
                this.updateComment(this.newComment)
            } else {
                this.createComment(this.newComment).then(() => {
                    this.getComments('/comments?photographCollection=' + this.$route.params.id)
                })
                    .catch((e) => {
                        window.console.log(e)
                        this.setError(e)
                        return e

                    })
            }

        },
        updateComment (comment) {

            return fetch(comment['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(comment)
            })
                .then(response => response.json())
                .then(() => {
                    this.getComments('/comments?photographCollection=' + this.$route.params.id)
                })
                .catch((e) => {
                    window.console.log(e)
                    this.setError(e)
                    return e

                })
        },
        commentClick (commentItem) {

            //if (this.activeComment===commentItem.id) return;
            this.oldScrollPos = window.scrollY
            let routeParams = {id: this.item['@id'], comment_id: commentItem.id}
            if (this.$route.params.id !== routeParams.id || this.$route.params.comment_id !== routeParams.comment_id) this.$router.replace({
                name: 'photographCollectionShowComment',
                params: routeParams,
                //hash: 'comment_'+routeParams.comment_id
            })

        },
        upvoteComment (commentItem) {
            return fetch(commentItem['@id'] + '/vote/up', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    this.getComments('/comments?photographCollection=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        downvoteComment (commentItem) {
            return fetch(commentItem['@id'] + '/vote/down', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    this.getComments('/comments?photographCollection=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        checkActiveComment () {
            window.console.log('checkActiveComment')
            if (typeof this.$route.params.comment_id !== 'undefined') {
                this.activeComment = decodeURIComponent(this.$route.params.comment_id)
                if (this.activeComment === false) this.activeComment = null
                this.showInfo = true

                setTimeout(() => {
                    if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                        if (this.scrollY > 0) window.scrollY = this.scrollY
                        //this.$vuetify.goTo(this.$refs['comment_' + this.activeComment][0], {});

                    }
                }, 1000)

            }
        },*/
        getCompleteUrl: function () {
            return window.location.origin + this.$route.fullPath
        },
        htmlAsText (value) {

            return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
        },
        setActiveTouchEle (item) {
            //console.log('setActiveTouchEle',item)
            this.activeTouchEle = item
        },
        getRatioRounded (item) {
            let ratio = 1
            if (item.thumbnailCoordinates && item.thumbnailCoordinates.feed) {
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height
            } else if (item.ratio) {
                ratio = item.ratio
            }
            if (ratio > 1.5) {
                return 2
            } else if (ratio < 0.8) {
                return 0.5
            }
            return 1
        },
        getRatioClass (item) {
            let miClass = ''
            let ratio = 1
            if (item.thumbnailCoordinates && item.thumbnailCoordinates.feed) {
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height
            } else if (item.box) {
                ratio = item.box.width / item.box.height
            } else if (item.ratio) {
                ratio = item.ratio
            }
            if (miClass == '') {
                if (ratio > 1.5) {
                    miClass = ' mi-horizontal'
                } else if (ratio < 0.8) {
                    miClass = ' mi-vertical'
                } else {
                    miClass = ' mi-small'
                }
            }
            return miClass
        },
        closeWin () {
            this.isActive = false
           this.$router.push('/collections')
        },
        onScroll () {
            if (this.isActive === false) return
            this.offsetTop = window.scrollY
            //window.console.log('onScroll offsetTop', this.offsetTop, this.isActive);
            this.albumThumbnailHeight = Math.min(window.innerHeight, window.innerWidth) - this.offsetTop
            this.albumThumbnailTop = 0
            this.albumThumbnailOpacity = 1
            if (this.albumThumbnailHeight < 100) {
                this.albumThumbnailTop = (this.albumThumbnailHeight - 100 )

                this.albumThumbnailOpacity = Math.min(100, this.albumThumbnailHeight) / 100
                this.albumThumbnailHeight = 100
            }
            if (typeof this.$refs['albumThumbnailImage'] !== 'undefined') this.$refs['albumThumbnailImage'].style = 'width: ' + this.albumThumbnailHeight + 'px; height: ' + this.albumThumbnailHeight + 'px; opacity: ' + this.albumThumbnailOpacity
            if (typeof this.$refs['albumThumbnail'] !== 'undefined') this.$refs['albumThumbnail'].style = 'top:' + this.albumThumbnailTop + 'px'

            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 2000
            if (bottomOfWindow) {
                if (this.view && this.view['hydra:next'] && !this.isPhotographListLoading) this.getPage(this.view['hydra:next'])
            }
        },
        setError (message) {
            this.error = message
            this.errorMessage = message
        },
        getStatusName (value) {
            let names = ['New', 'Crowdfunding', 'Funded', 'Photos scanned', 'Done','Received digitally']
            return names[value]
        },
        async onPerkSelect(perk, perkConfig) {
            if (!this.token_data) {
                this.showLoginDialog = true;
                this.crowdfundingDrawer = false;
                return;
            }
            this.selectedPerk = perk
            this.selectedPerkConfig = perkConfig
            if (this.checkPerkConfig(perk, perkConfig)) {
                //weiter zur Zahlung...

                let newOrderCollection = {};
                newOrderCollection[this.item.id] = {
                    photographCollection: this.item['@id'],
                    items: [
                        {
                            product: perk.product['@id'],
                            quantity: 1,
                            config: perkConfig,
                        }
                    ]
                };
                if (this.token_data) {
                    await this.personOrder('/people/' + this.token_data.person_id + '/orders')
                } else {
                    window.console.log('no tokenData!')
                }
                if (this.personLastOrder) {
                    const {
                        status,
                        paymentStatus,
                        paymentCheckoutUrl,
                        paymentMethod,
                        paymentRedirectUrl
                    } = this.personLastOrder;
                    const isPaymentOpen = paymentStatus === 'open';
                    const isStatusNull = status == null;
                    const hasCheckoutUrl = paymentCheckoutUrl !== null;
                    const isBankTransfer = paymentMethod === 'banktransfer';
                    const hasRedirectUrl = paymentRedirectUrl !== null;

                    if (isStatusNull && isPaymentOpen && hasCheckoutUrl) {
                        if (isBankTransfer && hasRedirectUrl) {
                            // Redirect to order created URL
                            window.location.href = paymentRedirectUrl;
                            return;
                        } else if (!isBankTransfer) {
                            // Redirect to payment checkout URL
                            window.location.href = paymentCheckoutUrl;
                            return;
                        }
                    }
                }

                this.createOrder(newOrderCollection[this.item.id]);
                this.setordercollection(Object.assign({}, this.order_collection, newOrderCollection));
                window.console.log(this.order_collection);

                //this.$router.push({name: 'OrderCollectionCreate', params: {id: this.item.id}})
            } else {
                //popup anzeigen....
                this.showPerkConfig = true
            }
        },
        checkPerkConfig (perk, perkConfig) {
            let retVal = true
            perk.product.configOptions.forEach(config => {
                if (typeof (perkConfig[config.id]) == 'undefined' || perkConfig[config.id] == null) retVal = false
            })
            return retVal
        },
        loadPerkProducts() {
            let productIds = []
            if (this.item.perks) this.item.perks.forEach(perk => {
                productIds.push(perk.product)
            })
            this.getProducts('products?id[]=' + productIds.join('&id[]='))
        },
        setRedirectRoute(route) {
            window.console.log('setRedirectRoute',route);
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: route}))
        },
        goToStart() {
            this.showLoginDialog=false;
            this.setRedirectRoute(this.$route.fullPath);
            this.$router.push({path: '/login'})
        }
    }
}
</script>
