<template>
    <v-autocomplete
        ref="autocomplete"
        v-model="inputValue"
        :items="filterItems"
        :label="label"
        outlined
        item-text="name"
        :search-input.sync="search"
        allow-overflow
        return-object
        hide-no-data
    >
        <!-- Custom slot for displaying name and image -->
        <template v-slot:item="{ item }">
            <v-row align="center" no-gutters>
                <v-col class="pa-0" cols="auto">
                    <v-img
                        :src="entrypoint + '/image_galleries/' + item.imagePath + '/big.jpeg'"
                        max-width="32"
                        max-height="32"
                        contain
                    />
                </v-col>
                <v-col class="pa-1" cols>
                    <span>{{ item.name }}</span>
                </v-col>
            </v-row>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        // The input data can be either 'mother', 'father', or 'newInput'
        inputType: {
            type: String,
            required: true
        },
        // Array of items for the autocomplete
        filterItems: {
            type: Array,
            required: true
        },
        entrypoint: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Object],
            default: ''
        },
        label: {
            type: String,
            default: 'Enter name'
        }
    },
    data() {
        return {
            search: '',
            inputValue: this.value, // Synchronize with the parent value
        };
    },
    watch: {
        inputValue(newValue) {
            this.$emit('input', newValue); // Emit the value back to parent on change
        }
    }
};
</script>
