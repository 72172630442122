
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import SubmissionError from "@/error/SubmissionError";

export const create = ({ commit }, values) => {
  commit(types.MENTION_CREATE_SET_ERROR, '')
  commit(types.MENTION_CREATE_TOGGLE_LOADING)

  const formData = new FormData();

  // Determine the name and optionally the id based on the type of newMention
  let name, id, description, mother, father,category;
  if (typeof values.newMention === 'object') {
    name = values.newMention.name;  // If it's an object, get the name property
    id = values.newMention.id;  // Get the id property if it's an object
  } else {
    name = values.newMention;
    description = values.newAlbumDescription;
    category = values.category;
    mother = values.mother ?? mother;
    father = values.father ?? father;
  }

  // Append the name and image to the FormData
  formData.append('name', name);
  formData.append('image', values.cropperImage);
  description && formData.append('description', description);
  category && formData.append('category',  category);
  mother && formData.append('mother', JSON.stringify(mother));  // Serialize `mother` as JSON
  father && formData.append('father', JSON.stringify(father));  // Serialize `father` as JSON;

  // If newMention is an object, also append the id
  if (id) {
    formData.append('id', id);
  }

  // Determine the correct URL based on the type of newMention
  const url = (typeof values.newMention === 'string') ? '/saveMention' : '/updateMention';

  // Perform the fetch request
  return fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.MENTION_CREATE_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.MENTION_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        // eslint-disable-next-line
        commit(types.MENTION_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.MENTION_CREATE_SET_ERROR, e.message)
    })
}
